@import '@angular/cdk/overlay-prebuilt.css';

@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';

@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';

html,
body {
  height: 100%;
  margin: 0;
}
@import '@angular/cdk/overlay-prebuilt.css';

@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';

@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';

html,
body {
  height: 100%;
  margin: 0;
}
